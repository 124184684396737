import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Stack,
  CardHeader,
  CardContent,
  MenuItem,
  Divider,
  Skeleton,
  IconButton,
  Tooltip,
  useTheme,
  Typography,
  LinearProgress,
  linearProgressClasses,
  Grid,
  Box
} from '@mui/material'
import { DateRange } from '@mui/x-date-pickers-pro'
import { Payment as PaymentIcon } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { format, parseISO } from 'date-fns'
import groupBy from 'lodash.groupby'
import { DatasetElementType } from '@mui/x-charts/internals'
import {
  LineChart,
  LineSeriesType,
  PieChart,
  PieValueType
} from '@mui/x-charts-pro'
import { mangoFusionPalette } from '@mui/x-charts/colorPalettes'

import {
  MuiCard,
  MuiDateRangePicker,
  MuiTextField,
  QueryError
} from 'components'
import {
  LocationFragmentFragment,
  Order_By,
  useGetUserLoanAndPaymentAggregationsQuery,
  UserLoanAggregationFragmentFragment,
  UserPaymentAggregationFragmentFragment
} from 'api/generated'
import {
  ALL,
  getPaymentsFilterPath,
  getUserPaymentAggregateWhere,
  getUserLoanAggregateWhere
} from 'utils/chart'
import { DictionariesQueryProps } from 'screens/Authenticated/Dashboard/Admin'
import { formatCurrency } from 'utils/currency'
import { sum, numOrZero, getPercentage } from 'utils/number'
import { PieCenterLabel } from 'components/MuiPieChart'
import { useThemeType } from 'context/theme'

export type PaymentsByDateChartsDataType = {
  location?: LocationFragmentFragment | null | undefined
  totalAmount: number
  dataset: DatasetElementType<string | number | Date | null | undefined>[]
  series: LineSeriesType[]
  payments: UserPaymentAggregationFragmentFragment[]
  loans: UserLoanAggregationFragmentFragment[]
  loansDataset: DatasetElementType<string | number | Date | null | undefined>[]
  loansSeries: LineSeriesType[]
  loanTotalAmount: number
}

export type PaymentsByDateChartsProps = {
  data: PaymentsByDateChartsDataType
}

const PaymentsByDateCharts: React.FC<PaymentsByDateChartsProps> = ({
  data
}: PaymentsByDateChartsProps) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const {
    mediaQueries: { mdDown }
  } = useThemeType()
  const colorPalettes = mangoFusionPalette(theme.palette.mode)
  const payments = data?.payments
  const aggregate = payments?.reduce(
    (acc, curr) => {
      return {
        amount: sum([acc?.amount, curr?.amount]),
        penalty_amount: sum([acc?.penalty_amount, curr?.penalty_amount]),
        capital_amount: sum([acc?.capital_amount, curr?.capital_amount]),
        commission_amount: sum([
          acc?.commission_amount,
          curr?.commission_amount
        ]),
        commission_interest_arrears_amount: sum([
          acc?.commission_interest_arrears_amount,
          curr?.commission_interest_arrears_amount
        ]),
        count: sum([acc?.count, curr?.count]),
        interest_amount: sum([acc?.interest_amount, curr?.interest_amount]),
        interest_arrears_amount: sum([
          acc?.interest_arrears_amount,
          curr?.interest_arrears_amount
        ])
      }
    },
    {
      amount: 0,
      capital_amount: 0,
      commission_amount: 0,
      commission_interest_arrears_amount: 0,
      count: 0,
      interest_amount: 0,
      interest_arrears_amount: 0,
      penalty_amount: 0
    }
  )
  const seriesData: PieValueType[] = [
    {
      id: 0,
      value: numOrZero(aggregate?.capital_amount),
      label: `${t('capital')}`,
      color: colorPalettes?.[0]
    },
    {
      id: 1,
      value: numOrZero(aggregate?.commission_amount),
      label: `${t('commission')}`,
      color: colorPalettes?.[1]
    },
    {
      id: 2,
      value: numOrZero(aggregate?.interest_amount),
      label: `${t('interest')}`,
      color: colorPalettes?.[2]
    },
    {
      id: 3,
      value: numOrZero(aggregate?.commission_interest_arrears_amount),
      label: `${t('commission_arrears')}`,
      color: colorPalettes?.[3]
    },
    {
      id: 4,
      value: numOrZero(aggregate?.interest_arrears_amount),
      label: `${t('interest_arrears')}`,
      color: colorPalettes?.[4]
    },
    {
      id: 5,
      value: numOrZero(aggregate?.penalty_amount),
      label: `${t('penalty')}`,
      color: colorPalettes?.[5]
    }
  ]

  return (
    <Stack spacing={1}>
      <Typography variant='h6'>{data?.location?.name}</Typography>

      <Stack
        spacing={2}
        direction={{ xs: 'column', md: 'row' }}
        alignItems={{ md: 'center' }}
        divider={
          <Divider orientation={mdDown ? 'horizontal' : 'vertical'} flexItem />
        }
      >
        <Stack
          spacing={2}
          direction='column'
          flex={2}
          divider={<Divider orientation='horizontal' flexItem />}
        >
          <Box>
            <Typography variant='body1'>
              {`${t('loans')}: `}
              <Typography variant='body2' fontWeight='bold' display='inline'>
                {formatCurrency({
                  amount: data?.loanTotalAmount
                })}
              </Typography>
            </Typography>

            <LineChart
              height={300}
              grid={{ vertical: true, horizontal: true }}
              dataset={data?.loansDataset}
              series={data?.loansSeries}
              slotProps={{
                loadingOverlay: { message: `${t('loading')}` },
                noDataOverlay: { message: `${t('noDataToDisplay')}` },
                legend: {
                  direction: 'row',
                  labelStyle: {
                    fontSize: theme.typography.body2.fontSize
                  },
                  itemMarkWidth: 12,
                  itemMarkHeight: 12
                }
              }}
              yAxis={[
                {
                  valueFormatter: value => {
                    if (!value) {
                      return value
                    }

                    return formatCurrency({
                      amount: Number(value),
                      withDefaultOptions: false,
                      options: {
                        notation: 'compact',
                        maximumFractionDigits: 1
                      }
                    })
                  }
                }
              ]}
              xAxis={[
                {
                  dataKey: 'x',
                  valueFormatter: value => {
                    if (!value) {
                      return value
                    }

                    return format(new Date(Number(value)), 'dd/MM')
                  }
                }
              ]}
            />
          </Box>

          <Box>
            <Typography variant='body1'>
              {`${t('payments')}: `}
              <Typography variant='body2' fontWeight='bold' display='inline'>
                {formatCurrency({
                  amount: data?.totalAmount
                })}
              </Typography>
            </Typography>

            <LineChart
              height={300}
              grid={{ vertical: true, horizontal: true }}
              dataset={data?.dataset}
              series={data?.series}
              slotProps={{
                loadingOverlay: { message: `${t('loading')}` },
                noDataOverlay: { message: `${t('noDataToDisplay')}` },
                legend: {
                  direction: 'row',
                  labelStyle: {
                    fontSize: theme.typography.body2.fontSize
                  },
                  itemMarkWidth: 12,
                  itemMarkHeight: 12
                }
              }}
              yAxis={[
                {
                  valueFormatter: value => {
                    if (!value) {
                      return value
                    }

                    return formatCurrency({
                      amount: Number(value),
                      withDefaultOptions: false,
                      options: {
                        notation: 'compact',
                        maximumFractionDigits: 1
                      }
                    })
                  }
                }
              ]}
              xAxis={[
                {
                  dataKey: 'x',
                  valueFormatter: value => {
                    if (!value) {
                      return value
                    }

                    return format(new Date(Number(value)), 'dd/MM')
                  }
                }
              ]}
            />
          </Box>
        </Stack>

        <Box flex={1}>
          <PieChart
            series={[
              {
                valueFormatter: value =>
                  formatCurrency({ amount: value?.value }),
                highlightScope: {
                  faded: 'global',
                  highlighted: 'item'
                },
                innerRadius: 75,
                outerRadius: 100,
                paddingAngle: 0,
                data: seriesData
              }
            ]}
            height={300}
            margin={{ top: 10, bottom: 10, left: 10, right: 10 }}
            slotProps={{
              legend: {
                hidden: true
              }
            }}
          >
            <PieCenterLabel
              primaryText={formatCurrency({
                amount: data?.totalAmount,
                options: { notation: 'compact', maximumFractionDigits: 2 }
              })}
              secondaryText={t('total')}
            />
          </PieChart>

          <Grid container rowSpacing={4} columnSpacing={2}>
            {seriesData.map(series => {
              const percentage = getPercentage(series?.value, data?.totalAmount)
              const value = formatCurrency({
                amount: series?.value
              })

              return (
                <Grid key={series?.id} item xs={12} md={6}>
                  <Stack direction='row'>
                    <Stack flex={1} gap={1}>
                      <Stack
                        direction='row'
                        justifyContent='space-between'
                        alignItems='center'
                      >
                        <Typography variant='body2' fontWeight={500}>
                          {series?.label as string}
                        </Typography>
                        <Typography variant='body2' color='text.secondary'>
                          {value}
                        </Typography>
                      </Stack>
                      <Tooltip title={`${percentage}%`}>
                        <LinearProgress
                          variant='determinate'
                          value={percentage}
                          sx={{
                            [`& .${linearProgressClasses.bar}`]: {
                              backgroundColor: series.color
                            }
                          }}
                        />
                      </Tooltip>
                    </Stack>
                  </Stack>
                </Grid>
              )
            })}
          </Grid>
        </Box>
      </Stack>
    </Stack>
  )
}

const PaymentsByDateChart: React.FC<DictionariesQueryProps> = ({
  dictionariesQuery
}: DictionariesQueryProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [locationId, setLocationId] = useState(ALL)
  const [userId, setUserId] = useState(ALL)
  const [dateRange, setDateRange] = useState<DateRange<Date>>([
    new Date(),
    new Date()
  ])
  const { data, loading, error, refetch } =
    useGetUserLoanAndPaymentAggregationsQuery({
      variables: {
        loan_where: getUserLoanAggregateWhere(dateRange, userId, locationId),
        loan_order_by: { loan_date: Order_By.Asc },
        payment_where: getUserPaymentAggregateWhere(
          dateRange,
          userId,
          locationId
        ),
        payment_order_by: { payment_date: Order_By.Asc }
      }
    })
  const dictionariesData = dictionariesQuery?.data
  const {
    mediaQueries: { mdDown }
  } = useThemeType()

  const handleChangeLocationId = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setLocationId(ev.target.value)
    },
    []
  )

  const handleChangeUserId = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setUserId(ev.target.value)
    },
    []
  )

  const handleChangeDateRange = useCallback((newDateRange: DateRange<Date>) => {
    setDateRange(newDateRange)
  }, [])

  const handleShowPaymentsInDetailTable = useCallback(() => {
    const selectedUser = dictionariesData?.users?.find(
      user => user?.id === userId
    )
    const selectedLocation = dictionariesData?.locations?.find(
      location => location?.id === locationId
    )
    const paymentsFilterPath = getPaymentsFilterPath(
      dateRange,
      selectedUser?.full_name,
      selectedLocation?.name
    )

    navigate(paymentsFilterPath)
  }, [dateRange, dictionariesData, userId, locationId, navigate])

  const handleRefetchQuery = useCallback(() => {
    refetch()
  }, [refetch])

  const paymentsGroupedByLocation = useMemo(
    () => groupBy(data?.userPaymentAggregations, 'location_id'),
    [data]
  )
  const loansGroupedByLocation = useMemo(
    () => groupBy(data?.userLoanAggregations, 'location_id'),
    [data]
  )
  const paymentLocationIds = Object.keys(paymentsGroupedByLocation)
  const loanLocationIds = Object.keys(loansGroupedByLocation)
  const uniqueLocationIds = useMemo(
    () => [...new Set([...paymentLocationIds, ...loanLocationIds])],
    [paymentLocationIds, loanLocationIds]
  )

  const chartsData = useMemo(
    () =>
      uniqueLocationIds?.map((key): PaymentsByDateChartsDataType => {
        const loans = loansGroupedByLocation?.[key]?.map(loan => ({
          ...loan,
          x: parseISO(loan?.loan_date)?.valueOf()
        }))

        const payments = paymentsGroupedByLocation?.[key]?.map(payment => ({
          ...payment,
          x: parseISO(payment?.payment_date)?.valueOf()
        }))

        const location = payments?.[0]?.location || loans?.[0]?.location
        const paymentsGroupedByPaymentDate = groupBy(payments, 'x')
        const paymentsGroupedByUser = groupBy(payments, 'user_id')
        const loansGroupedByLoanDate = groupBy(loans, 'x')
        const loansGroupedByCreatedBy = groupBy(loans, 'created_by_id')
        let totalAmount = 0
        let loanTotalAmount = 0

        const dataset = Object.keys(paymentsGroupedByPaymentDate)?.map(key => {
          const data = paymentsGroupedByPaymentDate?.[key]
          const response: DatasetElementType<
            string | number | Date | null | undefined
          > = {
            x: key
          }

          data?.forEach(item => {
            const amount = (item?.amount || 0) + (item?.penalty_amount || 0)
            totalAmount += amount

            response[item?.user_id] = amount
          })

          return response
        })

        const series: LineSeriesType[] = Object.keys(
          paymentsGroupedByUser
        )?.map(key => {
          const data = paymentsGroupedByUser?.[key]
          const user = data?.[0]?.user

          return {
            type: 'line',
            dataKey: user?.id,
            label: user?.full_name || '',
            stack: 'total',
            connectNulls: true,
            valueFormatter: value =>
              value === null ? '' : formatCurrency({ amount: Number(value) })
          }
        })

        const loansDataset = Object.keys(loansGroupedByLoanDate)?.map(key => {
          const data = loansGroupedByLoanDate?.[key]
          const response: DatasetElementType<
            string | number | Date | null | undefined
          > = {
            x: key
          }

          data?.forEach(item => {
            const amount = item?.amount || 0
            loanTotalAmount += amount

            response[item?.created_by_id] = amount
          })

          return response
        })

        const loansSeries: LineSeriesType[] = Object.keys(
          loansGroupedByCreatedBy
        )?.map(key => {
          const data = loansGroupedByCreatedBy?.[key]
          const user = data?.[0]?.created_by

          return {
            type: 'line',
            dataKey: user?.id,
            label: user?.full_name || '',
            stack: 'total',
            connectNulls: true,
            valueFormatter: value =>
              value === null ? '' : formatCurrency({ amount: Number(value) })
          }
        })

        return {
          location,
          dataset,
          totalAmount,
          series,
          payments,
          loans,
          loanTotalAmount,
          loansDataset,
          loansSeries
        }
      }),
    [uniqueLocationIds, loansGroupedByLocation, paymentsGroupedByLocation]
  )

  return (
    <MuiCard>
      <CardHeader
        title={t('payments')}
        action={
          <Tooltip title={t('showInDetail')}>
            <IconButton onClick={handleShowPaymentsInDetailTable}>
              <PaymentIcon />
            </IconButton>
          </Tooltip>
        }
      />

      <CardContent>
        <Stack spacing={2}>
          <Stack spacing={2} direction='row'>
            <MuiTextField
              select
              label={t('user')}
              size='small'
              onChange={handleChangeUserId}
              value={userId}
            >
              <MenuItem value={ALL}>{t(ALL)}</MenuItem>
              {dictionariesData?.users?.map(user => {
                return (
                  <MenuItem
                    key={user?.id}
                    value={user?.id}
                  >{`${user?.full_name}`}</MenuItem>
                )
              })}
            </MuiTextField>

            <MuiTextField
              select
              label={t('location')}
              size='small'
              onChange={handleChangeLocationId}
              value={locationId}
            >
              <MenuItem value={ALL}>{t(ALL)}</MenuItem>
              {dictionariesData?.locations?.map(location => {
                return (
                  <MenuItem key={location?.id} value={location?.id}>
                    {location?.name}
                  </MenuItem>
                )
              })}
            </MuiTextField>
          </Stack>

          <MuiDateRangePicker
            value={dateRange}
            onChange={handleChangeDateRange}
          />

          {error ? (
            <QueryError
              error={error}
              loading={loading}
              refetch={handleRefetchQuery}
            />
          ) : loading && !data ? (
            <Stack
              spacing={2}
              direction={{ xs: 'column', md: 'row' }}
              divider={
                <Divider
                  orientation={mdDown ? 'horizontal' : 'vertical'}
                  flexItem
                />
              }
            >
              <Skeleton
                variant='rectangular'
                width='100%'
                sx={{ minWidth: 620 }}
                height={600}
              />
              <Skeleton
                variant='rectangular'
                width='100%'
                sx={{ minWidth: 620 }}
                height={600}
              />
            </Stack>
          ) : (
            <Stack spacing={2} divider={<Divider />}>
              {chartsData?.map(chartData => {
                return (
                  <PaymentsByDateCharts
                    key={chartData?.location?.id}
                    data={chartData}
                  />
                )
              })}
            </Stack>
          )}
        </Stack>
      </CardContent>
    </MuiCard>
  )
}

export default PaymentsByDateChart
